import {
  AcademicCapIcon,
  BriefcaseIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  PresentationChartLineIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/solid";
import Container from "./Container";

const FEATURES = [
  {
    icon: <PresentationChartLineIcon className='w-6' />,
    title: "Sustainability Insights",
    description:
      "Harness actionable insights from your ESG data to drive strategic decisions and enhance sustainability performance.",
  },
  {
    icon: <AcademicCapIcon className='w-6' />,
    title: "Compliance & Reporting",
    description:
      "Achieve compliance with ease. Navigate the complexities of GRI, SASB, TCFD, and more for comprehensive ESG reporting.",
  },
  {
    icon: <BriefcaseIcon className='w-6' />,
    title: "Strategic ESG Integration",
    description:
      "Integrate ESG principles strategically into your business operations to unlock value and foster sustainable growth.",
  },
  {
    icon: <CurrencyDollarIcon className='w-6' />,
    title: "Financial Sustainability",
    description:
      "Leverage ESG insights to enhance financial performance, manage risks, and identify investment opportunities.",
  },
  {
    icon: <RectangleStackIcon className='w-6' />,
    title: "Stakeholder Engagement",
    description:
      "Strengthen stakeholder relations by transparently communicating your ESG efforts and achievements.",
  },
  {
    icon: <Cog6ToothIcon className='w-6' />,
    title: "Operational Efficiency",
    description:
      "Optimize your operations with ESG-focused innovations and practices that reduce costs and environmental impact.",
  },
];

const FeaturesGrid = () => {
  return (
    <section className='bg-indigo-300/10 text-slate-800 trapezoid-clip-b'>
      <Container xpadded ypadded>
        <div id='features' className='mb-16 max-md:flex flex-col items-center w-full'>
          <div className='max-w-screen-md'>
            <h2 className='mb-4 text-4xl lg:text-6xl tracking-tight font-semibold text-indigo-500'>
              Empowering Your ESG Journey
            </h2>
            <p className='sm:text-xl'>
              ESGConsultant is designed to transform your approach to sustainability, combining
              expert compliance guidance with strategic insights to drive growth and impact.
            </p>
          </div>
        </div>
        <div className='space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0'>
          {FEATURES.map(({ icon, title, description }) => {
            return (
              <div className='flex' key={title}>
                <div className='flex shrink-0 items-center justify-center mb-4 w-12 h-12 rounded-full bg-indigo-400 mr-5 text-indigo-50'>
                  {icon}
                </div>
                <div className='flex flex-col'>
                  <h3 className='mb-2 text-xl font-bold'>{title}</h3>
                  <p>{description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default FeaturesGrid;
