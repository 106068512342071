import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import FeaturesGrid from "./components/FeaturesGrid";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import FeaturesDetailed from "./components/FeaturesDetailed";
import GradientCanvas from "./components/GradientCanvas";
import { Helmet } from "react-helmet";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/Blog/BlogPost";

function App() {
  return (
    <Router>
      <Helmet>
        <title>ESGConsultant - AI-Assisted ESG Reporting SaaS Solution</title>
        <meta
          name="description"
          content="ESGConsultant is an innovative AI-assisted platform designed to streamline ESG reporting and compliance for businesses of all sizes. Simplify your sustainability reporting process with our automated, data-driven solutions and align your business with global ESG standards."
        />
        <meta
          name="keywords"
          content="ESG reporting, sustainability, compliance, AI-assisted, SaaS, ESG standards, environmental, social, governance"
        />
        <link rel="canonical" href="https://www.esg-consultant.com" />
        <meta
          property="og:title"
          content="ESGConsultant - AI-Assisted ESG Reporting SaaS Solution"
        />
        <meta
          property="og:description"
          content="Simplify your ESG reporting with ESGConsultant, the AI-assisted platform designed for comprehensive compliance and strategic sustainability performance improvement."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.esg-consultant.com" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "ESGConsultant",
            applicationCategory: "BusinessApplication",
            offers: {
              "@type": "Offer",
              price: "0",
              priceCurrency: "USD",
            },
            description:
              "AI-assisted platform designed to streamline ESG reporting and compliance for businesses of all sizes.",
          })}
        </script>
      </Helmet>
      <main className="font-['Poppins']">
        <Header />
        <GradientCanvas />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <FeaturesGrid />
                <FeaturesDetailed />
                <Faq />
              </>
            }
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
        <Footer />
      </main>
    </Router>
  );
}

export default App;