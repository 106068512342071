import featureOne from "../assets/feature-one.svg";
import featureTwo from "../assets/feature-two.svg";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Container from "./Container";

const FEATURES = [
  {
    image: featureOne,
    title: "Work with tools you already use",
    description:
      "Integrate ESG reporting seamlessly with your existing tools and systems. Our platform supports a wide array of standards including GRI, SASB, TCFD, and CDP, making it easier for you to maintain compliance and enhance sustainability reporting without the need for extensive reconfiguration.",
    checklist: [
      "Seamless integration with existing systems",
      "Support for major ESG reporting standards",
      "Automated data collection and reporting",
    ],
    footer:
      "Streamline your ESG reporting process, reduce manual effort, and ensure compliance with global standards, all while using the tools you already trust.",
  },
  {
    reverse: true,
    image: featureTwo,
    title: "We invest in the world’s potential",
    description:
      "Our commitment extends beyond software. We believe in empowering businesses to achieve their sustainability goals by providing not just tools, but also insights, expertise, and a community focused on ESG excellence. Join us in driving meaningful change towards a sustainable and equitable future.",
    checklist: [
      "Insights from global sustainability leaders",
      "Access to a community of ESG-focused professionals",
      "Expertise in ESG strategy and reporting",
    ],
    footer:
      "With ESGConsultant, you're not just adopting a platform; you're joining a movement towards a more sustainable and responsible global economy.",
  },
];

const FeatureCheklistWithImage = ({
  title,
  image,
  footer,
  checklist,
  description,
  reverse = false,
}) => {
  return (
    <div className={`flex items-center max-lg:flex-col ${reverse && "flex-row-reverse"}`}>
      <div
        className={`flex flex-col basis-1/2 text-lg max-w-screen-md  ${
          reverse ? "lg:ml-12" : "lg:mr-12"
        }`}>
        <h2 className='mb-4 text-4xl lg:text-5xl font-semibold tracking-tight text-slate-800'>
          {title}
        </h2>
        <p className='font-light lg:text-xl'>{description}</p>
        <ul className='pt-8 space-y-5 border-t border-primary-900/10 my-7'>
          {checklist.map(item => {
            return (
              <li className='flex space-x-3' key={item}>
                <CheckCircleIcon className='w-5 text-indigo-400' />
                <span className='text-base font-medium leading-tight'>{item}</span>
              </li>
            );
          })}
        </ul>
        <p className='mb-8 lg:text-lg'>{footer}</p>
      </div>
      <div className='flex shadow-lg shadow-gray-100 border border-gray-200 rounded-lg basis-1/2 max-w-screen-md'>
        <img src={image} alt='placeholder' className='rounded-lg' />
      </div>
    </div>
  );
};

const FeaturesDetailed = () => {
  return (
    <section className='text-slate-800'>
      <Container xpadded ypadded>
        <div className='space-y-20'>
          {FEATURES.map(feature => {
            return <FeatureCheklistWithImage {...feature} key={feature.title} />;
          })}
        </div>
      </Container>
    </section>
  );
};

export default FeaturesDetailed;
