const HeroCTA = () => {
  return (
    <div className='flex w-full items-baseline space-x-10 font-medium'>
      <a
        href='#features'
        className='w-1/2 sm:w-1/3 bg-indigo-500 text-center justify-center py-4 rounded-full text-white'>
        See our features
      </a>
      {/* <a href="#" className="text-center text-slate-800">
        Contact Sales
      </a> */}
    </div>
  );
};

export default HeroCTA;
