import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";
import { getPost } from "../utils/blogUtils";

// Custom components for markdown rendering
const components = {
  h1: ({ node, ...props }) => (
    <h1 className="text-3xl font-bold mt-8 mb-4" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-2xl font-semibold mt-6 mb-3" {...props} />
  ),
  h3: ({ node, ...props }) => (
    <h3 className="text-xl font-medium mt-4 mb-2" {...props} />
  ),
  p: ({ node, ...props }) => <p className="mb-4" {...props} />,
  ul: ({ node, ...props }) => <ul className="list-disc pl-5 mb-4" {...props} />,
  ol: ({ node, ...props }) => (
    <ol className="list-decimal pl-5 mb-4" {...props} />
  ),
  li: ({ node, ...props }) => <li className="mb-2" {...props} />,
  a: ({ node, ...props }) => (
    <a className="text-blue-600 hover:underline" {...props} />
  ),
  blockquote: ({ node, ...props }) => (
    <blockquote
      className="border-l-4 border-gray-300 pl-4 italic my-4"
      {...props}
    />
  ),
  code: ({ node, inline, ...props }) =>
    inline ? (
      <code className="bg-gray-100 rounded px-1" {...props} />
    ) : (
      <pre className="bg-gray-100 rounded p-4 overflow-x-auto">
        <code {...props} />
      </pre>
    ),
};

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    async function fetchPost() {
      try {
        const fetchedPost = await getPost(slug);
        setPost(fetchedPost);
      } catch (err) {
        setError(
          err.message || "Failed to fetch blog post. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    }
    fetchPost();
  }, [slug]);

  if (isLoading) return <div className="text-center py-8">Loading...</div>;
  if (error)
    return <div className="text-center py-8 text-red-500">Error: {error}</div>;
  if (!post) return <div className="text-center py-8">Post not found.</div>;

  const formattedDate = format(new Date(post.date), "MMMM d, yyyy");

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
      <p className="text-gray-600 mb-8">{formattedDate}</p>
      <div className="prose lg:prose-xl">
        <ReactMarkdown components={components}>{post.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default BlogPost;
