import { NAV_ITEMS } from "./Header";
import { Link } from "react-router-dom";

const HeaderNav = () => {
  return (
    <div className="max-lg:hidden text-lg">
      <ul className="flex font-regular space-x-8 text-white">
        {NAV_ITEMS.map(({ title, href }) => {
          return (
            <li key={title}>
              <Link to={href}>{title}</Link>
            </li>
          );
        })}
        <li>
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNav;
