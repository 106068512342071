const HeroHeadlines = () => {
  return (
    <>
      <div className='font-medium mb-10 leading-none relative text-[13vw] sm:text-[10vw] lg:text-[7vw] xl:text-[6vw]'>
        <h1 className='text-white mix-blend-overlay translate-3d-0'>
          Transforming ESG <br /> Reporting
        </h1>
        <h1 className='text-black absolute inset-0 -z-10' aria-hidden='true'>
          Transforming ESG <br /> Reporting
        </h1>
      </div>
      <p className='text-lg text-slate-800 mb-10 max-w-screen-sm mr-5'>
        ESGConsultant: Leading the Path to Sustainability. Navigate the complexities of ESG reporting
        and performance with our advanced analytics and compliance tools. Drive sustainable growth
        and create value with strategic insights tailored for your business.
      </p>
    </>
  );
};

export default HeroHeadlines;
