import matter from "gray-matter";

const API_URL = "http://localhost:3001/api";

export async function getAllPosts() {
  try {
    const response = await fetch(`${API_URL}/posts`);
    if (!response.ok) {
      throw new Error("Server responded with an error");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw new Error("Failed to fetch posts");
  }
}

export async function getPost(slug) {
  try {
    console.log(`Fetching post with slug: ${slug}`);
    const response = await fetch(`${API_URL}/posts/${slug}`);
    if (!response.ok) {
      throw new Error(
        `Server responded with ${response.status}: ${response.statusText}`
      );
    }
    const text = await response.text(); // Get the raw text first
    console.log("Received raw response:", text);
    try {
      const data = JSON.parse(text); // Then try to parse it
      console.log("Parsed data:", data);
      return data;
    } catch (parseError) {
      console.error("Error parsing JSON:", parseError);
      throw new Error("Received invalid JSON from server");
    }
  } catch (error) {
    console.error("Error in getPost:", error);
    throw error; // Throw the original error for more detailed debugging
  }
}
