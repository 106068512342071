import Container from "./Container";
import Accordion from "./Accordion";

const FAQ = [
  {
    question: "Why ESG reporting is essential for your business?",
    answer:
      "ESG reporting is crucial as it highlights a company's commitment to sustainable and ethical practices, influences investor decisions, and can significantly impact a company's reputation and financial performance. Implementing ESG reporting practices demonstrates transparency, can lead to better investment opportunities, and helps in managing risks related to environmental, social, and governance issues.",
  },
  {
    question: "How can ESGConsultant help with compliance and reporting?",
    answer:
      "ESGConsultant simplifies the complex process of ESG reporting and compliance. Our platform offers tools and resources aligned with global standards like GRI, SASB, TCFD, and CDP, ensuring your reporting is accurate, comprehensive, and up-to-date. With our solution, you can automate data collection, streamline reporting processes, and gain valuable insights to improve your ESG performance.",
  },
  {
    question: "Is ESGConsultant suitable for businesses of all sizes?",
    answer:
      "Absolutely! ESGConsultant is designed to cater to businesses of all sizes, from startups to large corporations. Our scalable solutions can be tailored to meet the unique needs and challenges of your business, ensuring you can effectively manage and report your ESG performance, regardless of the size of your operation.",
  },
  {
    question: "Can ESGConsultant assist in improving our ESG ratings?",
    answer:
      "Yes, ESGConsultant can play a significant role in improving your ESG ratings. By providing comprehensive tools for accurate reporting, strategic insights, and performance management, our platform helps you address key areas of improvement, enhance sustainability practices, and ultimately, positively impact your ESG ratings.",
  },
];

const Faq = () => {
  return (
    <section className='text-slate-800 bg-indigo-50 trapezoid-clip-t'>
      <div id='faq' className='mx-auto max-w-screen-md'>
        <Container xpadded ypadded>
          <div className='text-center mb-8 lg:mb-12'>
            <h2 className='mb-24 text-4xl lg:text-6xl tracking-tight font-semibold'>
              Frequently Asked Questions About ESG Reporting
            </h2>
          </div>
          {FAQ.map(({ question, answer }) => {
            return <Accordion title={question} description={answer} key={question} />;
          })}
        </Container>
      </div>
      <div id='waitlist-container' />
    </section>
  );
};

export default Faq;
