import Logo from "../Logo";
import HeaderNav from "./HeaderNav";
import HeaderButtons from "./HeaderButtons";
import HeaderMobileMenu from "./HeaderMobileMenu";
import Container from "../Container";

export const NAV_ITEMS = [
  {
    title: "Home",
    href: "/",
    description: "Learn more about what we do",
  },
  {
    title: "Features",
    href: "/#features",
    description: "See our features",
  },
  // {
  //   href: "#",
  //   title: "Testimonials",
  //   description: "See what our customers have to say",
  // },
  // {
  //   href: "#",
  //   title: "Pricing",
  //   description: "See our pricing",
  // },
  {
    title: "FAQs",
    href: "/#faq",
    description: "See our features",
  },
];

const Header = () => {
  return (
    <header>
      <nav className='py-10 text-white'>
        <Container xpadded>
          <div className='flex justify-between items-center'>
            <Logo />
            <HeaderNav />
            <div className='flex items-center max-lg:hidden'>
              <HeaderButtons />
            </div>
            <div className='lg:hidden z-10 mt-1'>
              <HeaderMobileMenu />
            </div>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;
