const HeaderButtons = () => {
  return (
    <div className='space-x-8 font-medium'>
      {/* <a href='#'>Log in</a> */}
      <a
        href='https://chat.openai.com/g/g-IqnQ7hRa8-esg-consultant'
        className='bg-white/20 px-5 py-2 rounded-full backdrop-blur-lg'>
        Get Started
      </a>
    </div>
  );
};

export default HeaderButtons;
